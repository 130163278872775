/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  data_X?: any //Make it optional so it won't break other components
  data_Y?: any
  chartHeading?: string
}

const MixedWidget8: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  data_X,
  data_Y,
  chartHeading,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(
      chartRef.current,
      chart1Options(chartColor, chartHeight, data_Y, data_X)
    )
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h2 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-2 mb-1'>{chartHeading}</span>
        </h2>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column px-0 pb-0'>
        {/* begin::Chart */}
        <div className='card-px'>
          <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
        </div>
        <div className='card-line-single mt-2'></div>
        {/* end::Chart */}
        {/* begin::Items */}
        <div className=''>
          {/* begin::Item */}
          <div className='d-flex flex-stack my-3'>
            {/* begin::Section */}
            <div className='d-flex align-items-center '>
              {/* begin::Title */}
              {/* <div>
                <h3 className='fs-2 text-gray-800 text-hover-primary fw-bold'>Active User</h3>
              </div> */}
              {/* end::Title */}
            </div>
            {/* end::Section */}

            {/* begin::Label */}
            {/* <div className='badge badge-light fw-semibold py-4 px-3'>+82$</div> */}

            <div className='card-px'>
              <div className='fs-2 fw-semibold px-3'>{data_Y.length}</div>
            </div>

            {/* end::Label */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chart1Options = (
  chartColor: string,
  chartHeight: string,
  data_Y: any,
  data_X: any
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  // const baseColor = getCSSVariableValue('--bs-' + chartColor) as string
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')

  const baseColor = '#A9DFD8'
  const linearGradientStop = '#1D1E26'

  return {
    series: [
      {
        name: 'Teacher ',
        data: data_Y,
        // data: [39, 30, 60, 25, 25, 40],
        // data: [
        //   [1327359600000, 60.95],
        //   [1327446000000, 41.34],
        //   [1327532400000, 31.18],
        //   [1327618800000, 21.05],
        //   [1327878000000, 51.0],
        //   [1327964400000, 10.95],
        // ],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    // fill: {
    //   type: 'solid',
    //   opacity: 1,
    // },
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 1.5,
        // gradientToColors: undefined,
        gradientToColors: [baseColor, linearGradientStop],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        // stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      categories: data_X,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        // formatter: undefined,
        // offsetY: 0,
        // style: {
        //   fontSize: '12px',
        // },
      },
    },
    yaxis: {
      min: 0,
      max: 65,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: false,
      // style: {
      //   fontSize: '12px',
      // },
      // y: {
      //   formatter: function (val) {
      //     return val + ' '
      //   },
      // },
    },
    colors: [lightColor],
    markers: {
      colors: [baseColor],
      size: 3,
      // @ts-ignore
      strokeColor: [baseColor],
      strokeWidth: 3,
      hover: {
        size: 5,
      },
    },
  }
}

export {MixedWidget8}
